<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="user_id"
            >
              <v-select
                v-model="searchFields.user_id"
                :dir="$store.state.appConfig.layout.direction"
                label="name"
                :options="users"
                :reduce="item => item.id"
                :placeholder="$t('labels.name')"
                :get-option-label="(option) => option.name"
                @input="inputChange($event, 'user_id')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.module_name')"
              label-for="modules"
            >
              <v-select
                v-model="searchFields.module_name"
                :dir="$store.state.appConfig.layout.direction"
                label="key"
                :options="modules"
                :reduce="item => item.value"
                :placeholder="$t('labels.module_name')"
                :get-option-label="(option) => option.key"
                @input="inputChange($event, 'module_name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.events')"
              label-for="events"
            >
              <v-select
                v-model="searchFields.event"
                :dir="$store.state.appConfig.layout.direction"
                label="key"
                :options="events"
                :reduce="item => item.value"
                :placeholder="$t('labels.events')"
                :get-option-label="(option) => option.key"
                @input="inputChange($event, 'event')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('Module number')"
              label-for="module_id"
            >
              <b-form-input
                id="module_id"
                :value="searchFields.module_id"
                :placeholder="$t('Module number')"
                @input="inputChange($event, 'module_id')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('Registration number')"
              label-for="id"
            >
              <b-form-input
                id="id"
                :placeholder="$t('Registration number')"
                :value="searchFields.id"
                @input="inputChange($event, 'id')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <DatePicker
              :label="$t('labels.created_on')"
              :reset="true"
              @input="inputChange($event, 'created_at')"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    >
      <template #custom_actions="{ item, generalActions }">
        <b-link
          v-b-tooltip.hover.v-success
          :title="$t('Details')"
          class="ml-1"
          :to="{name: 'view-audits', params: { resourceId: item.id }}"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'audits',
      singularName: 'audits',
      users: [],
      modules: [],
      events: [],
      searchFields: {
        user_id: null,
        module_name: null,
        event: null,
        module_id: null,
        id: null,
        created_at: null,
      },
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getUsers() {
      this.axios.get('/audits/constants')
        .then(res => {
          const { data } = res.data
          this.users = data.users
          this.modules = data.modules
          this.events = data.events
        })
    },
  },
}
</script>
